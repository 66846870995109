import { Field } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import useFormGroupPartnerInfo from "./hooks"

import "./style.scss"

import FormGroupPartnerAddress from "../FormGroupPartnerAddress"

const FormGroupPartnerInfo = ({ form, isLoading }) => {
  const {
    shareIncome,
    basicInfoFields,
    partnerMaritalStatus,
    partnerOccupationCategory,
    partnerMonthlyIncome,
    sameAddress,
    isSameAddress,
    isMarried,
    isStableUnion,
    isShareIncome,
    hangleShareIncomeChange,
    handleSameAddress,
    proposalData,
    partnerCnpj
  } = useFormGroupPartnerInfo()

  return (
    <div className="formGroupLeadPartnerInfo">
      <Text as="h2" className="formGroupLeadPartnerInfo__subtitle">
        inclusão de renda
      </Text>
      <div
        className="formGroupLeadPartnerInfo__card"
        role="radiogroup"
        aria-label="deseja compor renda com outra pessoa?"
      >
        <If
          condition={!(isMarried || isStableUnion)}
          renderIf={
            <>
              <Text
                as="p"
                className="formGroupLeadPartnerInfo__cardInfo--strong"
              >
                deseja compor renda com outra pessoa?
              </Text>
              <Text as="p" className="formGroupLeadPartnerInfo__cardInfo">
                Lembre-se que ela entrará como participante no contrato e que
                ela não deve ser casada ou conviver em união estável com outra
                pessoa, ok?
              </Text>
            </>
          }
          renderElse={
            <Text as="p" className="formGroupLeadPartnerInfo__cardInfo">
              deseja compor renda com o cônjuge?
            </Text>
          }
        />
        <div className="formGroupLeadPartnerInfo__cardRadioGroup">
          <Field
            field={shareIncome}
            form={form}
            onInput={(event) => hangleShareIncomeChange(event.target.value)}
            isDisabled={isLoading}
          />
        </div>
      </div>
      <If
        condition={isShareIncome}
        renderIf={
          <>
            <Text as="h2" className="formGroupLeadPartnerInfo__title">
              dados pessoais de quem irá compor a renda
            </Text>

            {Object.values(basicInfoFields).map((field) => (
              <Field
                autoComplete="off"
                key={field.name}
                field={field}
                form={form}
                data-testid={field.name}
                disabled={isLoading}
              />
            ))}
            <If
              condition={!isMarried}
              renderIf={
                <Field
                  field={partnerMaritalStatus}
                  form={form}
                  data-testid={partnerMaritalStatus.name}
                  disabled={isLoading}
                />
              }
            />
            <Text as="h2" className="formGroupLeadPartnerInfo__title">
              sobre a renda
            </Text>
            <Field
              field={partnerOccupationCategory}
              form={form}
              data-testid={partnerOccupationCategory.name}
              disabled={isLoading}
            />
            <If
              condition={
                proposalData.partner_occupation_category ===
                "SOCIO_PROPRIETARIO"
              }
              renderIf={
                <Field
                  field={partnerCnpj}
                  form={form}
                  data-testid={partnerCnpj.name}
                  disabled={isLoading}
                />
              }
            />
            <Field
              autoComplete="off"
              field={partnerMonthlyIncome}
              rules={{ min: 0, max: 999_999_999.99 }}
              form={form}
              data-testid={partnerMonthlyIncome.name}
              disabled={isLoading}
            />

            <Text as="h2" className="formGroupLeadPartnerInfo__subtitle">
              endereço de quem irá compor renda
            </Text>
            <div
              role="radiogroup"
              aria-label="mora no mesmo endereço que você?"
            >
              <Text as="p" className="formGroupLeadPartnerInfo__cardInfo">
                mora no mesmo endereço que você?
              </Text>
              <div className="formGroupLeadPartnerInfo__radioGroup">
                <Field
                  field={sameAddress}
                  form={form}
                  onClick={(event) => handleSameAddress(event.target.value)}
                  data_testid={sameAddress.name}
                  isDisabled={isLoading}
                />
              </div>
            </div>

            <If
              condition={!isSameAddress}
              renderIf={
                <FormGroupPartnerAddress form={form} isLoading={isLoading} />
              }
            />
          </>
        }
      />
      <If
        condition={!isShareIncome & (isMarried || isStableUnion)}
        renderIf={
          <>
            <Text as="h2" className="formGroupLeadPartnerInfo__title">
              dados pessoais da pessoa companheira
            </Text>

            {Object.values(basicInfoFields).map((field) => (
              <Field
                autoComplete="off"
                key={field.name}
                field={field}
                form={form}
                data-testid={field.name}
              />
            ))}
          </>
        }
      />
    </div>
  )
}

export default FormGroupPartnerInfo
