import {
  MARITAL_STATUS_OPTIONS,
  NATIONALITY_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS
} from "../../../../constants/dropdownOptions"

export const fields = {
  shareIncome: {
    type: "radio",
    name: "share_income",
    options: [
      {
        id: "share_income_nao",
        value: "nao",
        label: <span aria-hidden={true}>não</span>,
        ariaLabel: "não desejo compor renda com outra pessoa",
        checked: true
      },
      {
        id: "share_income_sim",
        value: "sim",
        label: <span aria-hidden={true}>sim</span>,
        ariaLabel: "sim, eu desejo compor renda com outra pessoa"
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  },
  partnerName: {
    type: "name",
    name: "partner_name",
    label: "nome completo",
    sendFormat: "text"
  },
  partnerCpf: {
    type: "cpf",
    name: "partner_cpf",
    label: "CPF",
    sendFormat: "cpf",
    fetchFormat: "cpf",
    rules: {
      distinctDocument: true
    }
  },
  partnerBirthdate: {
    type: "birthdate",
    name: "partner_birthdate",
    label: "data de nascimento",
    sendFormat: "date",
    fetchFormat: "date",
    rules: {
      minAge: 18,
      finacingTermWithAgePartner: 80.5
    }
  },
  partnerNationality: {
    type: "select",
    name: "partner_nationality",
    label: "nacionalidade",
    placeholder: "selecione",
    options: NATIONALITY_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select",
    defaultValue: "BRASILEIRA"
  },
  partnerMaritalStatus: {
    type: "select",
    name: "partner_marital_status",
    label: "estado civil",
    sendFormat: "select",
    fetchFormat: "select",
    placeholder: "selecione",
    options: MARITAL_STATUS_OPTIONS
  },
  partnerOccupationCategory: {
    type: "select",
    name: "partner_occupation_category",
    label: "tipo de renda",
    placeholder: "selecione",
    options: OCCUPATION_CATEGORY_OPTIONS,
    sendFormat: "select",
    fetchFormat: "select"
  },
  partnerCnpj: {
    type: "cnpj",
    name: "partner_cnpj",
    label: "cnpj",
    sendFormat: "cnpj",
    fetchFormat: "cnpj"
  },
  partnerMonthlyIncome: {
    type: "currency",
    name: "partner_monthly_income",
    label: "renda total mensal"
  },
  sameAddress: {
    type: "radio",
    name: "same_address",
    options: [
      {
        id: "same_address_nao",
        value: "nao",
        label: <span aria-hidden={true}>não</span>,
        ariaLabel: "não moro no mesmo endereço de quem irá compor a renda"
      },
      {
        id: "same_address_sim",
        value: "sim",
        label: <span aria-hidden={true}>sim</span>,
        ariaLabel: "sim, moro no mesmo endereço de quem irá compor a renda",
        checked: true
      }
    ],
    sendFormat: "bool",
    fetchFormat: "bool"
  }
}
