import { useEffect } from "react"

import {
  useForm,
  usePayloadFormatters,
  useStepTransition
} from "@garantidos/hooks"
import { resetFocus } from "@garantidos/utils/js/accessibility"

import usePayloadHelper from "pages/Proposal/data/payload/usePayloadHelper"
import { useFlags } from "pages/Proposal/hooks/useFlags"
import validatorsDictionary from "pages/Proposal/hooks/validators"

import { fields as formGroupPartnerAddressFields } from "./components/FormGroupPartnerAddress/fields"
import { fields as formGroupPartnerInfoFields } from "./components/FormGroupPartnerInfo/fields"

import { ProposalContext, useProposalContext } from "contexts/proposal"

import { fields } from "./fields"
import * as trackers from "./trackings"

const useLead = () => {
  const allFields = {
    ...fields,
    ...formGroupPartnerInfoFields,
    ...formGroupPartnerAddressFields
  }

  const { form } = useForm({
    fields: allFields,
    context: ProposalContext,
    validatorsDictionary
  })
  const {
    stepInfo,
    proposalData,
    fetchProposalData,
    sendProposalData,
    cpfData,
    errorMessage,
    setErrorMessage,
    errorHandler,
    isLoading,
    setIsStepVisible
  } = useProposalContext()
  const { stepNavigator } = useStepTransition(setIsStepVisible)
  const { formatSendPayload } = usePayloadFormatters(allFields)
  const { isLeadRetired, isMarried, isStableUnion } = useFlags()
  const { createPayload } = usePayloadHelper()

  const { marital_status } = proposalData
  const { hashedCpf } = cpfData
  const { previousStep, nextStep } = stepInfo

  useEffect(() => {
    resetFocus()
  }, [])

  useEffect(() => {
    trackers.pageview(hashedCpf)

    const backButton = document.querySelector(".ds-header__button-back")
    backButton.onclick = () => trackers.backHome(hashedCpf)
  }, [])

  const onSubmitCallback = async (formData) => {
    if (isLoading) return
    try {
      const formattedFields = formatSendPayload(formData)
      if (formattedFields.marital_status === "CASADO")
        formattedFields.partner_marital_status = "CASADO"
      const payload = createPayload(formattedFields)
      await sendProposalData({ payload })

      trackers.advance()
      stepNavigator("next", nextStep)
    } catch (error) {
      trackers.alert(hashedCpf, error)
      errorHandler(error)
    }
  }

  const handlePrevStep = async () => {
    if (isLoading) return
    try {
      await fetchProposalData({ step: previousStep, recaptcha: true })
      trackers.previousGA4()
      stepNavigator("prev", previousStep)
    } catch (error) {
      errorHandler(error)
    }
  }

  return {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    flags: { isLeadRetired, isMarried, isStableUnion },
    validMaritalStatus: marital_status !== undefined,
    errorMessage,
    setErrorMessage,
    isLoading,
    proposalData
  }
}

export default useLead
