import { Field, Form, HousingAlert, Sender } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import FormGroupPartnerInfo from "./components/FormGroupPartnerInfo"

import useLead from "./hooks"

import "./style.scss"

const Lead = () => {
  const {
    handlePrevStep,
    onSubmitCallback,
    fields,
    form,
    flags,
    validMaritalStatus,
    errorMessage,
    setErrorMessage,
    isLoading,
    proposalData
  } = useLead()

  const { isValid } = form
  const {
    nationality,
    occupationCategory,
    cnpj,
    monthlyIncome,
    maritalStatus,
    stableUnion
  } = fields

  const { isMarried } = flags

  return (
    <div className="proposalLead">
      <div className="proposalLead__formWrapper">
        <HousingAlert
          type="error"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Text
          as="h1"
          size="lg"
          className="simulation__title"
          data-aos="fade-left"
          data-aos-delay="200"
          data-aos-duration="800"
        >
          você deu mais um passo para realizar seu sonho!
        </Text>
        <Text
          as="p"
          size="md"
          className="simulation__subtitle"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          Precisamos de algumas informações para a análise de crédito. Mas não
          se preocupe, esta etapa é sem compromisso.
        </Text>
        <Form
          onSubmit={onSubmitCallback}
          form={form}
          data-aos="fade-left"
          data-aos-delay="400"
          data-aos-duration="800"
        >
          <Field
            field={nationality}
            form={form}
            data-testid={nationality.name}
            disabled={isLoading}
          />
          <Field
            field={occupationCategory}
            form={form}
            data-testid={occupationCategory.name}
            disabled={isLoading}
          />
          <If
            condition={
              proposalData.occupation_category === "SOCIO_PROPRIETARIO"
            }
            renderIf={
              <Field
                field={cnpj}
                form={form}
                data-testid={cnpj.name}
                disabled={isLoading}
              />
            }
          />
          <Field
            autoComplete="off"
            field={monthlyIncome}
            form={form}
            rules={{ min: 0, max: 999_999_999.99 }}
            data-testid={monthlyIncome.name}
            disabled={isLoading}
          />
          <Field
            field={maritalStatus}
            form={form}
            data-testid={maritalStatus.name}
            disabled={isLoading}
          />
          <If
            condition={!isMarried && validMaritalStatus}
            renderIf={
              <div
                className="proposalLead__selectGroup"
                role="radiogroup"
                aria-label="você convive em união estável?"
              >
                <Text as="p" className="proposalLead__label">
                  você convive em união estável?
                </Text>
                <div className="proposalLead__selectWrapper">
                  <Field
                    field={stableUnion}
                    form={form}
                    required
                    isDisabled={isLoading}
                  />
                </div>
              </div>
            }
          />
          <If
            condition={validMaritalStatus}
            renderIf={
              <FormGroupPartnerInfo form={form} isLoading={isLoading} />
            }
          />
          <div className="proposalLead__senderWrapper">
            <Sender
              showPrev
              onPrev={handlePrevStep}
              isNextDisabled={!isValid}
              data_testid="submit"
              isLoading={isLoading}
            />
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Lead
